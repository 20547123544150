<template>
  <v-container>
    <v-row v-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate color="primary" size="32"></v-progress-circular>
    </v-row>
    <v-row v-else dense>
      <v-col cols="12" v-if="hasInformation">
        <h3 class="text-center"><b>Itens do Pacote:</b></h3>
        <v-simple-table dense>
          <thead>
            <tr class="grey lighten-2">
              <th class="text-left">ID</th>
              <th class="text-left">Tipo</th>
              <th class="text-left">Item</th>
              <th class="text-left">Valor</th>
              <th class="text-left">Ilimitado</th>
              <th class="text-left">Quantidade</th>
              <th class="text-left">Início da Vigência</th>
              <!-- <th class="text-left">Fim da Vigência</th> -->
              <!-- <th class="text-left">Ativo</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="pacote in itempacotes" :key="pacote.id" class="grey lighten-3">
              <td>{{ pacote.id }}</td>
              <td>{{ getItemType(pacote) }}</td>
              <td>{{ getItemName(pacote) }}</td>
              <td>{{ formatarReal(getItemPreco(pacote)) }}</td>
              <td>{{ pacote.ilimitado ? 'Sim' : 'Não' }}</td>
              <td>{{ pacote.quantidade || '' }}</td>
              <td>{{ formatDate(pacote.inicio_vigencia) }}</td>
              <!-- <td>{{ pacote.fim_vigencia ? formatDate(pacote.fim_vigencia) : '' }}</td> -->
              <!-- <td>{{ pacote.ativo ? 'Sim' : 'Não' }}</td> -->
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="12" v-else>
        <p class="text-center"><strong>Sem Itens Cadastrados</strong></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UtilsFunc from "../../../service/utilsFunc";
import api from "../../../http";

export default {
  name: "Pacoteexpand",
  data: () => UtilsFunc.withCRUDUtils({
    itempacotes: [],
    loading: true,
  }),
  props: {
    item: Object,
    pacoteId: Number,
    idoperadora:Number,
  },
  methods: {
    async getItemPacote() {
      const LOADING_NAME = 'get:itempacote';
      this.setLoading(LOADING_NAME);
      this.loading = true;
      try {
        const { data } = await api.get(`operadoras/operadora-itempacotes/?pacote=${this.pacoteId}`);
        const filteredData = data.filter(pacote => pacote.pacote.id === this.pacoteId);
        this.itempacotes = filteredData
        .filter(item => item.ativo )
        .sort((a, b) => {
          const order = { taxa: 1, procedimento: 2, equipamento: 3 };
          const aType = a.taxa ? 'taxa' : a.procedimento ? 'procedimento' : 'equipamento';
          const bType = b.taxa ? 'taxa' : b.procedimento ? 'procedimento' : 'equipamento';
          return order[aType] - order[bType];
        });
      } catch (error) {
        alert("Erro ao solicitar os Pacotes Ativos");
      } finally {
        this.loading = false;
        this.setLoading(LOADING_NAME, false);
      }
    },
    formatDate(dateString) {
      if (!dateString) return 'Não cadastrada';
      const date = new Date(dateString);
      date.setDate(date.getDate() + 1); // Adiciona um dia à data
      return date.toLocaleDateString('pt-BR');
    },
    getItemType(pacote) {
      if (pacote.taxa) return 'Taxa';
      if (pacote.equipamento) return 'Equipamento';
      if (pacote.procedimento) return 'Procedimento';
      return '';
    },
    getItemName(pacote) {
      if (pacote.taxa) return pacote.taxa.taxa.nome;
      if (pacote.equipamento) return pacote.equipamento.equipamento.nome;
      if (pacote.procedimento) return pacote.procedimento.procedimento.nome;
      return '';
    },
    getItemPreco(pacote) {
      if (pacote.taxa) return pacote.taxa.preco;
      if (pacote.equipamento) return pacote.equipamento.preco;
      if (pacote.procedimento) return pacote.procedimento.preco;
      return 0;
    },
    formatarReal(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    },
  },
  computed: {
    hasInformation() {
      return this.itempacotes.length > 0;
    }
  },
  mounted() {
    this.getItemPacote();
  },
};
</script>
